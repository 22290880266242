import Axios from "axios"
import { getCookie } from "@/utils/auth"
// 创建axios实例
const server = Axios.create({
    baseURL: process.env.VUE_APP_Proxy_API,
    // withCredentials: true,
    timeout: 50000 // 请求超时时间
})

// 添加请求拦截器
server.interceptors.request.use(
    (config) => {
        /** 设置默认token */
        let code = getCookie('code'),
            key = getCookie('key')
        // 请求头
        if(code) {
            config.headers['productCode'] = code
        }
        if(key) {
            config.headers['apiKey'] = key
        }
        return config
    },
    (error) => {
        // console.log(error.errorMsg)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
server.interceptors.response.use(
    (response) => {
        // 请求成功返回的数据
        let data = response.data
        if (data.errorCode === 200) {
            // 成功
            return data
        } else {
            // 失败
            // message.error(data.errorMsg)
            return Promise.reject (response)
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default server
