import request from "@/api/testAPI/index"

// 获取设备最新上报数据
/*
    param type - 查询类型（1、查询属性，2、查询动作，3、查询服务）
    param deviceCode - 设备code
*/
export function getEquipData(type, deviceCode) {
    return request({
        url: `/api/iotdata/latest/${type}/${deviceCode}`,
        method: 'get'
    })
}

// 设备历史数据查询
/*
    param time1 - 查询开始时间
    param time2 - 查询结束时间
    param code - 设备code
    param num - 查询类型（1、查询属性，2、查询动作，3、查询服务）
*/
export function getEquipHistory(time1, time2, code, num) {
    return request({
        url: "/api/iotdata/query",
        method: 'post',
        data: {
            startTime: time1,
            endTime: time2,
            deviceCode: code,
            type: num
        }
    })
}

// 新增设备
/*
    param val - 设备code，值需唯一
    param desc - 设备描述
    param site - 设备安装地址
    param num - 设备安装地址经度
    param num1 - 设备安装地址纬度
*/
export function addEquip(val, desc, site, num, num1) {
    return request({
        url: "/api/device/save",
        method: 'post',
        data: {
            code: val,
            remark: desc,
            address: site,
            longitue: num,
            latitude: num1
        }
    })
}

// 更新设备信息
/*
    param id - 当前需要更新的设备id
    param desc - 设备描述
    param site - 设备安装地址
    param num - 设备安装地址经度
    param num1 - 设备安装地址纬度
*/
export function updateEquip(val, desc, site, num, num1) {
    return request({
        url: "/api/device/update",
        method: 'post',
        data: {
            id: val,
            remark: desc,
            address: site,
            longitue: num,
            latitude: num1
        }
    })
}

// 删除设备
/*
    param id - 当前需要删除的设备id
*/
export function deleteEquip(id) {
    return request({
        url: `/api/device/remove/${id}`,
        method: 'post'
    })
}

// 获取设备详情
/*
    param id - 当前需要获取详情的设备id
*/
export function getEquipDetails(id) {
    return request({
        url: `/api/device/get/${id}`,
        method: 'get'
    })
}
