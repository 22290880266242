var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productWrap" },
    [
      _c("div", { staticClass: "APIWrap" }, [
        _c(
          "div",
          {
            staticClass: "APILeft APILeftList",
            staticStyle: { "padding-left": "0" },
          },
          [
            _c(
              "a-collapse",
              {
                model: {
                  value: _vm.activeKey,
                  callback: function ($$v) {
                    _vm.activeKey = $$v
                  },
                  expression: "activeKey",
                },
              },
              [
                _c(
                  "a-collapse-panel",
                  { key: "1", attrs: { header: "设备管理" } },
                  [
                    _c(
                      "p",
                      {
                        style: _vm.index === "0" ? "color: #1890ff" : "",
                        on: {
                          click: function ($event) {
                            return _vm.chooseApi("获取设备最新上报数据", "0")
                          },
                        },
                      },
                      [_vm._v(" 获取设备最新上报数据 ")]
                    ),
                    _c(
                      "p",
                      {
                        style: _vm.index === "1" ? "color: #1890ff" : "",
                        on: {
                          click: function ($event) {
                            return _vm.chooseApi("设备历史数据查询", "1")
                          },
                        },
                      },
                      [_vm._v(" 设备历史数据查询 ")]
                    ),
                    _c(
                      "p",
                      {
                        style: _vm.index === "2" ? "color: #1890ff" : "",
                        on: {
                          click: function ($event) {
                            return _vm.chooseApi("新增设备", "2")
                          },
                        },
                      },
                      [_vm._v(" 新增设备 ")]
                    ),
                    _c(
                      "p",
                      {
                        style: _vm.index === "3" ? "color: #1890ff" : "",
                        on: {
                          click: function ($event) {
                            return _vm.chooseApi("更新设备信息", "3")
                          },
                        },
                      },
                      [_vm._v(" 更新设备信息 ")]
                    ),
                    _c(
                      "p",
                      {
                        style: _vm.index === "4" ? "color: #1890ff" : "",
                        on: {
                          click: function ($event) {
                            return _vm.chooseApi("删除设备", "4")
                          },
                        },
                      },
                      [_vm._v(" 删除设备 ")]
                    ),
                    _c(
                      "p",
                      {
                        style: _vm.index === "5" ? "color: #1890ff" : "",
                        on: {
                          click: function ($event) {
                            return _vm.chooseApi("获取设备详情", "5")
                          },
                        },
                      },
                      [_vm._v(" 获取设备详情 ")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "APILeft" },
          [
            _c(
              "p",
              { staticClass: "APITop" },
              [
                _c("span", [_vm._v(_vm._s(_vm.APIName))]),
                _c(
                  "router-link",
                  { attrs: { tag: "a", target: "_blank", to: "/file" } },
                  [_c("span", [_vm._v("查看API文档")])]
                ),
              ],
              1
            ),
            _c(
              "a-form-model",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShow,
                    expression: "isShow",
                  },
                ],
                ref: "ruleForm",
                attrs: { model: _vm.form, rules: _vm.rules },
              },
              [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "productCode", prop: "productCode" } },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.form.productCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productCode", $$v)
                        },
                        expression: "form.productCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "apiKey", prop: "apiKey" } },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.form.apiKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "apiKey", $$v)
                        },
                        expression: "form.apiKey",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "0",
                        expression: "index === '0'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "type", prop: "type" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "deviceCode", prop: "code" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "code", $$v)
                            },
                            expression: "form.code",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "1",
                        expression: "index === '1'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "startTime", prop: "startTime" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.startTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startTime", $$v)
                            },
                            expression: "form.startTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "endTime", prop: "endTime" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "endTime", $$v)
                            },
                            expression: "form.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "deviceCode", prop: "deviceCode" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.deviceCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deviceCode", $$v)
                            },
                            expression: "form.deviceCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "type", prop: "searchType" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.searchType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "searchType", $$v)
                            },
                            expression: "form.searchType",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "2",
                        expression: "index === '2'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "code", prop: "equipCode" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.equipCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "equipCode", $$v)
                            },
                            expression: "form.equipCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "remark", prop: "remark" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "address", prop: "address" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "address", $$v)
                            },
                            expression: "form.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "longitue", prop: "longitue" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.longitue,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "longitue", $$v)
                            },
                            expression: "form.longitue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "latitude", prop: "latitude" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.latitude,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "latitude", $$v)
                            },
                            expression: "form.latitude",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "3",
                        expression: "index === '3'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "id", prop: "deviceId" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.deviceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deviceId", $$v)
                            },
                            expression: "form.deviceId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "remark", prop: "deviceRemark" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.deviceRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deviceRemark", $$v)
                            },
                            expression: "form.deviceRemark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "address", prop: "deviceAddress" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.deviceAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deviceAddress", $$v)
                            },
                            expression: "form.deviceAddress",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "longitue", prop: "deviceLongitue" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.deviceLongitue,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deviceLongitue", $$v)
                            },
                            expression: "form.deviceLongitue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "latitude", prop: "deviceLatitude" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.deviceLatitude,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deviceLatitude", $$v)
                            },
                            expression: "form.deviceLatitude",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "4",
                        expression: "index === '4'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "id", prop: "id" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "id", $$v)
                            },
                            expression: "form.id",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.index === "5",
                        expression: "index === '5'",
                      },
                    ],
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "id", prop: "detailsId" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.detailsId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "detailsId", $$v)
                            },
                            expression: "form.detailsId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onExecute },
                      },
                      [_vm._v(" 执行 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "APILeft", staticStyle: { "border-right": "none" } },
          [
            _c("p", { staticClass: "APITop" }, [
              _c("span", [_vm._v("调试结果")]),
              _c(
                "span",
                {
                  staticStyle: { color: "#1890ff", cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      _vm.isVisible = true
                    },
                  },
                },
                [_vm._v("查看执行历史")]
              ),
            ]),
            _c("p", { staticStyle: { color: "red" } }, [
              _vm._v(" *接口调试会直接对线上资源进行操作，请小心执行 "),
            ]),
            _c("p", [_vm._v("调试流程：")]),
            _c("p", [_vm._v("1.选择要试用的接口")]),
            _c("p", [_vm._v("2.根据接口填写所需的参数")]),
            _c("p", [_vm._v("3.执行后再该窗口等待接口")]),
            _c("p", [_vm._v("执行结果：")]),
            _c("p", [_vm._v(_vm._s(_vm.result))]),
          ]
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "接口调用执行历史",
            visible: _vm.isVisible,
            footer: null,
          },
          on: {
            cancel: function ($event) {
              _vm.isVisible = false
            },
          },
        },
        [
          _c("a-table", {
            staticStyle: { "margin-bottom": "20px" },
            attrs: {
              locale: { emptyText: "暂无数据" },
              "table-layout": "auto",
              columns: _vm.columns,
              pagination: false,
              "row-key": "id",
              "data-source": _vm.data,
            },
            scopedSlots: _vm._u([
              {
                key: "param",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.onLook(record)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.useParam(record)
                          },
                        },
                      },
                      [_vm._v("复用该参数")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }