<template>
    <div class="productWrap">
        <!-- <p>
            <span class="productTitle">API调试</span>
        </p> -->
        <div class="APIWrap">
            <div class="APILeft APILeftList" style="padding-left: 0">
                <!-- <a-input-search placeholder="输入API名称或代码搜索" style="margin-bottom: 20px" enter-button @search="onSearch" /> -->
                <a-collapse v-model="activeKey">
                    <a-collapse-panel key="1" header="设备管理">
                        <p :style="index === '0' ? 'color: #1890ff' : ''" @click="chooseApi('获取设备最新上报数据', '0')">
                            获取设备最新上报数据
                        </p>
                        <p :style="index === '1' ? 'color: #1890ff' : ''" @click="chooseApi('设备历史数据查询', '1')">
                            设备历史数据查询
                        </p>
                        <p :style="index === '2' ? 'color: #1890ff' : ''" @click="chooseApi('新增设备', '2')">
                            新增设备
                        </p>
                        <p :style="index === '3' ? 'color: #1890ff' : ''" @click="chooseApi('更新设备信息', '3')">
                            更新设备信息
                        </p>
                        <p :style="index === '4' ? 'color: #1890ff' : ''" @click="chooseApi('删除设备', '4')">
                            删除设备
                        </p>
                        <p :style="index === '5' ? 'color: #1890ff' : ''" @click="chooseApi('获取设备详情', '5')">
                            获取设备详情
                        </p>
                    </a-collapse-panel>
                </a-collapse>
            </div>
            <div class="APILeft">
                <p class="APITop">
                    <span>{{ APIName }}</span>
                    <router-link tag="a" target="_blank" to="/file">
                        <span>查看API文档</span>
                    </router-link>
                </p>
                <a-form-model
                    v-show="isShow"
                    ref="ruleForm"
                    :model="form"
                    :rules="rules">
                    <a-form-model-item label="productCode" prop="productCode">
                        <a-input
                            v-model="form.productCode" />
                    </a-form-model-item>
                    <a-form-model-item label="apiKey" prop="apiKey">
                        <a-input
                            v-model="form.apiKey" />
                    </a-form-model-item>
                    <div v-show="index === '0'">
                        <a-form-model-item label="type" prop="type">
                            <a-input
                                v-model="form.type" />
                        </a-form-model-item>
                        <a-form-model-item label="deviceCode" prop="code">
                            <a-input
                                v-model="form.code" />
                        </a-form-model-item>
                    </div>
                    <div v-show="index === '1'">
                        <a-form-model-item label="startTime" prop="startTime">
                            <a-input
                                v-model="form.startTime" />
                        </a-form-model-item>
                        <a-form-model-item label="endTime" prop="endTime">
                            <a-input
                                v-model="form.endTime" />
                        </a-form-model-item>
                        <a-form-model-item label="deviceCode" prop="deviceCode">
                            <a-input
                                v-model="form.deviceCode" />
                        </a-form-model-item>
                        <a-form-model-item label="type" prop="searchType">
                            <a-input
                                v-model="form.searchType" />
                        </a-form-model-item>
                    </div>
                    <div v-show="index === '2'">
                        <a-form-model-item label="code" prop="equipCode">
                            <a-input
                                v-model="form.equipCode" />
                        </a-form-model-item>
                        <a-form-model-item label="remark" prop="remark">
                            <a-input
                                v-model="form.remark" />
                        </a-form-model-item>
                        <a-form-model-item label="address" prop="address">
                            <a-input
                                v-model="form.address" />
                        </a-form-model-item>
                        <a-form-model-item label="longitue" prop="longitue">
                            <a-input
                                v-model="form.longitue" />
                        </a-form-model-item>
                        <a-form-model-item label="latitude" prop="latitude">
                            <a-input
                                v-model="form.latitude" />
                        </a-form-model-item>
                    </div>
                    <div v-show="index === '3'">
                        <a-form-model-item label="id" prop="deviceId">
                            <a-input
                                v-model="form.deviceId" />
                        </a-form-model-item>
                        <a-form-model-item label="remark" prop="deviceRemark">
                            <a-input
                                v-model="form.deviceRemark" />
                        </a-form-model-item>
                        <a-form-model-item label="address" prop="deviceAddress">
                            <a-input
                                v-model="form.deviceAddress" />
                        </a-form-model-item>
                        <a-form-model-item label="longitue" prop="deviceLongitue">
                            <a-input
                                v-model="form.deviceLongitue" />
                        </a-form-model-item>
                        <a-form-model-item label="latitude" prop="deviceLatitude">
                            <a-input
                                v-model="form.deviceLatitude" />
                        </a-form-model-item>
                    </div>
                    <div v-show="index === '4'">
                        <a-form-model-item label="id" prop="id">
                            <a-input
                                v-model="form.id" />
                        </a-form-model-item>
                    </div>
                    <div v-show="index === '5'">
                        <a-form-model-item label="id" prop="detailsId">
                            <a-input
                                v-model="form.detailsId" />
                        </a-form-model-item>
                    </div>
                    <a-form-model-item style="text-align: right">
                        <a-button type="primary" @click="onExecute">
                            执行
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="APILeft" style="border-right: none">
                <p class="APITop">
                    <span>调试结果</span>
                    <span style="color: #1890ff; cursor: pointer" @click="isVisible = true">查看执行历史</span>
                </p>
                <p style="color: red">
                    *接口调试会直接对线上资源进行操作，请小心执行
                </p>
                <p>调试流程：</p>
                <p>1.选择要试用的接口</p>
                <p>2.根据接口填写所需的参数</p>
                <p>3.执行后再该窗口等待接口</p>
                <p>执行结果：</p>
                <p>{{ result }}</p>
            </div>
        </div>
        <!-- 查看API执行历史 -->
        <a-modal
            :width="800"
            title="接口调用执行历史"
            :visible="isVisible"
            :footer="null"
            @cancel="isVisible = false">
            <a-table style="margin-bottom: 20px"
                     :locale="{emptyText: '暂无数据'}"
                     table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
                <template slot="param" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="onLook(record)">查看</a>
                </template>
                <template slot="operation" slot-scope="text, record">
                    <a href="javascript:void(0);" @click="useParam(record)">复用该参数</a>
                </template>
            </a-table>
        </a-modal>
    </div>
</template>

<script>
import { setCookie } from "@/utils/auth"
import { getEquipData, getEquipHistory, addEquip, updateEquip, deleteEquip, getEquipDetails } from '@/api/testAPI'
const columns = [
    {
        title: '请求时间',
        dataIndex: 'time',
        key: 'time'
    },
    {
        title: '接口名',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '是否成功',
        dataIndex: 'des',
        key: 'des'
    },
    {
        title: '参数',
        width: '60px',
        scopedSlots: { customRender: 'param' }
    },
    {
        title: '操作',
        width: '120px',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        id: 0,
        name: 'skdjis',
        des: '成功',
        time: '2021-1-1'
    },
    {
        id: 1,
        name: 'skdjis',
        des: '成功',
        time: '2021-1-1'
    },
    {
        id: 2,
        name: 'skdjis',
        des: '成功',
        time: '2021-1-1'
    }
]
export default {
    name: "APIDebug",
    data() {
        return {
            // 当前选中的tab项
            index: '',
            // 折叠面板默认展开项
            activeKey: ['1'],
            // 执行结果
            result: '',
            // API调试表单数据
            form: {
                productCode: '',
                apiKey: '',
                type: '',
                code: '',
                startTime: '',
                endTime: '',
                searchType: '',
                deviceCode: '',
                equipCode: '',
                remark: '',
                address: '',
                longitue: '',
                latitude: '',
                deviceId: '',
                deviceRemark: '',
                deviceAddress: '',
                deviceLongitue: '',
                deviceLatitude: '',
                id: '',
                detailsId: ''
            },
            // API调试表单验证规则
            rules: {
                productCode: [
                    { required: true, message: '请输入productCode', trigger: 'blur' }
                ],
                apiKey: [
                    { required: true, message: '请输入apiKey', trigger: 'blur' }
                ]
            },
            // API是否为调试状态
            isShow: false,
            APIName: '接口名',
            // 执行历史弹窗是否显示
            isVisible: false,
            columns,
            data
        }
    },
    methods: {
        // 搜索操作
        onSearch() {},
        // 选择API列表中的任一项
        chooseApi(val, num) {
            this.APIName = val
            this.index = num
            this.isShow = true
            this.$delete(this.rules, 'type')
            this.$delete(this.rules, 'code')
            this.$delete(this.rules, 'deviceCode')
            this.$delete(this.rules, 'equipCode')
            this.$delete(this.rules, 'deviceId')
            this.$delete(this.rules, 'id')
            this.$delete(this.rules, 'detailsId')
            if (num === '0') {
                this.rules.type = [
                    { required: true, message: '请输入type', trigger: 'blur' }
                ]
                this.rules.code = [
                    { required: true, message: '请输入deviceCode', trigger: 'blur' }
                ]
            } else if (num === '1') {
                this.rules.deviceCode = [
                    {required: true, message: '请输入deviceCode', trigger: 'blur'}
                ]
            } else if (num === '2') {
                this.rules.equipCode = [
                    {required: true, message: '请输入code', trigger: 'blur'}
                ]
            } else if (num === '3') {
                this.rules.deviceId = [
                    {required: true, message: '请输入id', trigger: 'blur'}
                ]
            } else if (num === '4') {
                this.rules.id = [
                    {required: true, message: '请输入id', trigger: 'blur'}
                ]
            } else {
                this.rules.detailsId = [
                    {required: true, message: '请输入id', trigger: 'blur'}
                ]
            }
        },
        // 查看参数详情操作
        onLook() {
            this.$success({
                title: '"version":1,"action":"QueryStatistics","project_id":"Tl8kxw"',
                okText: '确定',
                centered: true,
            })
        },
        // 执行操作
        onExecute() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    setCookie('code', this.form.productCode)
                    setCookie('key', this.form.apiKey)
                    let info = this.form
                    if(this.index === '0') {
                        getEquipData(info.type, info.code).then((res) => {
                            this.result = res.data || res.errorMsg
                        }).catch((res) => {
                            this.result = res.data.errorMsg
                        })
                    } if (this.index === '1') {
                        getEquipHistory(info.startTime, info.endTime, info.deviceCode, info.searchType).then((res) => {
                            this.result = res.data || res.errorMsg
                        }).catch((res) => {
                            this.result = res.data.errorMsg
                        })
                    } if (this.index === '2') {
                        addEquip(info.equipCode, info.remark, info.address, info.longitue, info.latitude).then((res) => {
                            this.result = res.data || res.errorMsg
                        }).catch((res) => {
                            this.result = res.data.errorMsg
                        })
                    } if (this.index === '3') {
                        updateEquip(info.deviceId, info.deviceRemark, info.deviceAddress, info.deviceLongitue, info.deviceLatitude).then((res) => {
                            this.result = res.data || res.errorMsg
                        }).catch((res) => {
                            this.result = res.data.errorMsg
                        })
                    } if (this.index === '4') {
                        deleteEquip(info.id).then((res) => {
                            this.result = res.data || res.errorMsg
                        }).catch((res) => {
                            this.result = res.data.errorMsg
                        })
                    } if (this.index === '5') {
                        getEquipDetails(info.detailsId).then((res) => {
                            this.result = res.data || res.errorMsg
                        }).catch((res) => {
                            this.result = res.data.errorMsg
                        })
                    }
                }
            })
        },
        // 复用参数操作
        useParam() {}
    }
}
</script>

<style lang="scss" scoped>
.productWrap {
    height: 96%;
    margin: 2% 40px;
    .productTitle {
        font-size: 20px;
        font-weight: bold;
        margin-right: 100px;
    }
    .APIWrap {
        display: flex;
        height: 96%;
        justify-content: space-between;
        .APILeft {
            width: 33%;
            padding: 0 3%;
            height: 100%;
            border-right: 1px solid #ccc;
            .APITop {
                display: flex;
                padding-bottom: 20px;
                justify-content: space-between;
                border-bottom: 1px solid #ccc;
            }
        }
        .APILeftList p:hover{
            color: #1890ff;
            cursor: pointer;
        }
    }
}
</style>
